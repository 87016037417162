import React, { useState } from 'react';
import { Image, RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import ModalVideo from 'react-modal-video';
import Link from '../../../core/Components/Link';

export default function CardSlider({ fields }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [videoId, setVideoId] = useState('');
  const videoPlayerHandler = (id) => {
    setVideoId(id.value);
    setIsModalOpen(true);
  };
  const responsive = {
    desktop: {
      breakpoint: { max: 4000, min: 767 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };
  return (
    <section className="cardSlider-container" id={fields.Id.value}>
      <div className="cardSlider-container-title-container">
        <p className="cardSlider-container-title-container-title">
          <Text field={fields.title} />
        </p>
        <p>
          <Link
            field={fields.link}
            className="cardSlider-container-title-container-link-text"
          />
        </p>
      </div>
      <div className="cardSlider-container-card-slider">
        <Carousel
          swipeable={false}
          responsive={responsive}
          ssr={true}
          showDots={true}
          className="cardSlider-container-card-slider-scroll-container"
        >
          {fields.cardSliders.map((item) => (
            <div
              className="cardSlider-container-card-slider-scroll-container-card-item"
              key={item.id}
            >
              <div className="cardSlider-container-card-slider-scroll-container-card-item-image-box">
                <Image field={item.fields.image} />
                {item.fields.videoId.value !== null &&
                item.fields.videoId.value !== '' ? (
                  <button
                    className="video-modal-cta"
                    type="button"
                    onClick={() => videoPlayerHandler(item.fields.videoId)}
                    aria-label="Play video"
                  />
                ) : null}
              </div>
              <div className="cardSlider-container-card-slider-scroll-container-card-item-info-box">
                <p className="cardSlider-container-card-slider-scroll-container-card-item-info-box-title-text">
                  <Text field={item.fields.title} />
                </p>
                <p className="cardSlider-container-card-slider-scroll-container-card-item-info-box-info-text">
                  <RichText field={item.fields.description} />
                </p>
                <Link
                  field={item.fields.link}
                  className="cardSlider-container-card-slider-scroll-container-card-item-info-box-user-text"
                />
              </div>
            </div>
          ))}
        </Carousel>
        {fields.cardSliders.length > 3 ? (
          <>
            <button
              aria-label="disabled-button"
              className="react-multiple-carousel__arrow react-multiple-carousel__arrow--left disabled"
              type="button"
            />
            <button
              aria-label="disabled-button"
              className="react-multiple-carousel__arrow react-multiple-carousel__arrow--right disabled"
              type="button"
            />
          </>
        ) : (
          ''
        )}

        <ModalVideo
          channel="youtube"
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          videoId={videoId}
          youtube={{
            autoplay: 1,
            enablejsapi: 1,
            rel: 0,
          }}
        />
      </div>
    </section>
  );
}
